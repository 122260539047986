<template>
  <div class="introduction">
    <!-- banner -->
    <div v-if=" fullWidth < 767">
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_mobile_' + $i18n.locale]?topBanner['cover_mobile_' + $i18n.locale]:topBanner.cover_mobile)"
      >
      <p v-html="(topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile) == ''? (topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description) : (topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile)"></p>
      </Banner>
    </div>
    <div v-else>
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_' + $i18n.locale]?topBanner['cover_' + $i18n.locale]:topBanner.cover)" 
      >
      <p v-html="(topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description)"></p>
      </Banner>
    </div>
    <div class="pc-con">
      <!-- about -->
      <div v-if=" fullWidth > 767" style="padding:0 200px ;">
        <about-page></about-page>
        <!-- video -->
        <in-video-page></in-video-page>
      </div>
      <div v-else><about-page></about-page></div>
      <div v-if=" fullWidth > 767" class="container">
        <!-- history -->
        <history-page></history-page>
      </div>
      <div v-else style="padding:0 20px ;">
        <!-- video -->
        <in-video-page></in-video-page>
        <!-- history -->
        <history-page></history-page>
      </div>
    </div>
    <!-- contact us -->
    <contact-page></contact-page>
    <!-- job -->
    <job-page></job-page>
  </div>
</template>
<script>
import AboutPage from './AboutPage.vue';
import InVideoPage from './InVideoPage.vue';
import HistoryPage from './HistoryPage.vue';
import ContactPage from './ContactPage.vue';
import JobPage from './JobPage.vue';
import Banner from '../../components/Banner'
import request from "../../api/request"
export default {
  components: {
    AboutPage,
    InVideoPage,
    HistoryPage,
    ContactPage,
    JobPage,
    Banner
  },
  data() {
      return {
          topBanner: {},
      staticUrl: request.staticUrl,
      fullWidth: 0,
    };
  },
  mounted(){
    this.banners();
    this.handleResize()
  },
  methods: {
    banners(){
      let _this = this
      request.get('/banners', {
        banner_type__in:'2'
        }).then(function (res) {
        if (res.status == 200) {
            _this.topBanner = res.data.results[0]
        }
      })
    },
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
<style>
.introduction .introduction-banner .img-box{
  height: 100%;
  position: relative;
}
.introduction .introduction-banner .img-box img{
  width: 100%;
  height: 100%;
}
.introduction .introduction-banner .img-box .text-box{
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  text-align: center;
  color: #fff;
}
.introduction .introduction-banner .img-box .text-box p{
  font-size: 34px;
  margin-top: 100px;
}
</style>
