<template>
  <div class="introduction-histort">
		<!-- pc -->
		<div class="pc-history">
			<div class="card-carousel-wrapper">
			<div class="card-carousel--nav__left" @click="moveCarousel(-1)" :disabled="atHeadOfList">
				<img src="../../assets/img/his_left.png" alt="" srcset="">
			</div>
			<div class="card-carousel">
				<div class="card-carousel--overflow-container">
					<div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
						<!-- <div class="card-carousel--card" v-for="(item,n) in items" :key="n"> -->
						<div v-for="(item,index) in historyList" :key="index" class="card-carousel--card">
									<div class="card-carousel--card--footer">
										<div class="title">{{item[`title_${$i18n.locale}`]}}</div>
										<div v-html="item[`content_${$i18n.locale}`]" class="content">
											<!-- <p><span>11</span>{{ $t(' ‘묵혼’ 태국 출시') }}</p>
											<p><span>10</span>{{ $t(' ‘RF 온라인’ 일본 서비스') }}</p>
											<p><span>07</span>{{ $t(' ‘포트리스 배틀로얄‘ 서비스 출시') }}</p>
											<p>{{ $t('‘묵혼’ 온라인 대만 출시') }}</p>
											<p><span>06</span>{{ $t(' ‘고수’ 대만 출시') }}</p>
											<p><span>04</span>{{ $t(' ‘묵혼’ 온라인 한국 출시') }}</p>
											<p>{{ $t('‘고수‘ 모바일 한국 출시') }}</p> -->
										</div>
									</div>
						</div>
						<!-- <div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2019</div>
								<div class="content">
									<p><span>12</span>{{ $t(' 온라인 SF MMORPG ‘RF온라인’ 서비스 출시') }}</p>
									<p><span>11</span>{{ $t(' 씨씨알컨텐츠트리와 ‘포트리스 2M’ 퍼블리싱 계약 체결') }}</p>
									<p><span>04</span>{{ $t(' 모바일 MMORPG ‘무협왕’ 앱 스토어 출시') }}</p>
									<p><span>02</span>{{ $t(' 모바일 MMORPG ‘리그너스 대륙전기‘ 구글 플레이, 원 스토어 출시') }}</p>
									<p>{{ $t('모바일 SRPG ‘삼국 어웨이큰＇구글 플레이, 원 스토어 출시') }}</p>
									<p><span>01</span>{{ $t(' 모바일 MMORPG ‘십만대적검 : 리부트‘출시') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2018</div>
								<div class="content">
									<p><span>12</span>{{ $t(' 모바일 MMORPG ‘백가쟁패’ 구글 플레이, 원 스토어 출시') }}</p>
									<p>{{ $t('모바일 MMORPG ‘신의 아이들‘구글 플레이, 앱 스토어 ,원 스토어 출시') }}</p>
									<p><span>09</span>{{ $t(' 모바일 MMORPG ‘십만대적검’ 구글 플레이, 원 스토어 출시') }}</p>
									<p><span>08</span>{{ $t(' 모바일 MMORPG ‘여신전기‘ 구글 플레이, 원 스토어 출시') }}</p>
									<p><span>03</span>{{ $t(' 모바일 무협 MMORPG ‘군도‘ 구글 플레이, 원 스토어 출시') }}</p>
									<p><span>01</span>{{ $t(' 웹 MMORPG ‘촉산협객전‘ 서비스 출시') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2017</div>
								<div class="content">
									<p><span>11</span>{{ $t(' 블루사이드와 ‘킹덤언더파이어‘ 모바일 게임 개발 업무 협약 체결') }}</p>
									<p><span>08</span>{{ $t(' 모바일 게임 ‘형님 모바일’ 구글 플레이 무협모바일게임 장르 1위 달성') }}</p>
									<p><span>05</span>{{ $t(' 웹 무협 MMORPG ‘검우강호’ 사전 등록자 15만명 기록') }}</p>
									<p><span>04</span>{{ $t(' 웹게임 ‘무쌍 온라인‘ 서비스 출시') }}</p>
									<p>{{ $t('㈜팡게임, ‘㈜팡스카이’로 사명 변경') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2016</div>
								<div class="content">
									<p><span>12</span>{{ $t(' 모바일 해적 전략시물레이션 게임 ‘무적함대1942’ 구글 플레이 출시') }}</p>
									<p><span>10</span>{{ $t(' 모바일 MMOARPG ‘칼에 깃든 바람‘ 원 스토어 출시') }}</p>
									<p><span>05</span>{{ $t(' 모바일게임 ‘대황제M’ 구글 플레이,원 스토어 출시, 구글 플레이 전략게임 1위 달성') }}</p>
									<p><span>01</span>{{ $t(' 무협 웹게임 ‘운중가온라인‘ 서비스 오픈') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2015</div>
								<div class="content">
									<p><span>12</span>{{ $t(' PlamJoy와 웹게임 ‘운중가온라인’ 한국 퍼블리싱 계약 체결') }}</p>
									<p><span>08</span>{{ $t(' PC온라인게임 ‘대황제’ 네이버 등 8개사 채널링 계약') }}</p>
									<p>{{ $t('모바일게임 ‘바다속이야기’ 구글 플레이, N스토어 출시') }}</p>
									<p><span>07</span>{{ $t(' PC온라인게임 ‘데빌에이지‘ 서비스 오픈') }}</p>
									<p><span>02</span>{{ $t(' 벤처기업확인(벤처기업협회)') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2014</div>
								<div class="content">
									<p><span>11</span>{{ $t(' PC온라인게임 “뉴던전히어로” 오픈') }}</p>
									<p><span>08</span>{{ $t(' ‘기업부설연구소 설립(미래창조과학부)') }}</p>
									<p><span>07</span>{{ $t(' 중국 최대 검색포털 ‘바이두‘의 “바이두모바일게임＂과 협력체결') }}</p>
									<p>{{ $t('모바일 게임 “구몽:전생의기억“  구글 플레이 출시') }}</p>
									<p><span>05</span>{{ $t(' 모바일 게임 “케로로 액션히어로 for Kakao 구글 플레이, 앱 스토어 ,원 스토어 출시') }}</p>
									<p><span>01</span>{{ $t(' 국내 모바일게임사 서비스팀 인수') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2013</div>
								<div class="content">
									<p><span>12</span>{{ $t(' PC온라인게임 “구몽온라인“ 6개 채널링 동시오픈') }}</p>
									<p><span>10</span>{{ $t(' 중국 ‘광주명조네트워크’와 PC온라인게임 “구몽온라인“ 한국 독점 서비스 계약체결') }}</p>
									<p><span>08</span>{{ $t(' ‘㈜투윈게임즈’와 PC온라인게임 “원더킹“ 채널링 계약 체결') }}</p>
									<p><span>07</span>{{ $t(' ‘㈜이야소프트’와 PC온라인게임 “에다전설“ 국내 서비스 계약 체결') }}</p>
									<p><span>06</span>{{ $t(' 중국 ‘자모조이’와 모바일게임 “스와트앤좀비” 서비스 계약 체결') }}</p>
									<p>{{ $t('‘㈜미디어로그’와 “스와트앤좀비“ 해외 서비스 계약 체결') }}</p>
								</div>
							</div>
						</div>
						<div class="card-carousel--card">
							<div class="card-carousel--card--footer">
								<div class="title">2012</div>
								<div class="content">
									<p><span>12</span>{{ $t(' www.panggame.com 사이트 오픈') }}</p>
									<p><span>10</span>{{ $t(' 주식회사 팡 게임 설립') }}</p>
									<p>{{ $t('‘㈜더파이널파이브’와 PC온라인게임 ‘’와인드업‘ 서비스 계약 체결') }}</p>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
			<div class="card-carousel--nav__right" @click="moveCarousel(1)" :disabled="atEndOfList">
				<img src="../../assets/img/his_right.png" alt="" srcset="">
			</div>
			</div>
		</div>
		<!-- mb -->
		<div class="mb-history">
			<div class="histitle">{{ $t('팡스카이가 걸어온 길') }}</div>
			<swiper class="mb-newspage" :options="swiperOption" ref="mySwiper">
				<!-- slides -->
				<swiper-slide v-for="(item,index) in historyList" :key="index">
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">{{item[`title_${$i18n.locale}`]}}</div>
							<div v-html="item[`content_${$i18n.locale}`]" class="content">
								<!-- <p><span>11</span>{{ $t(' ‘묵혼’ 태국 출시') }}</p>
								<p><span>10</span>{{ $t(' ‘RF 온라인’ 일본 서비스') }}</p>
								<p><span>07</span>{{ $t(' ‘포트리스 배틀로얄‘ 서비스 출시') }}</p>
								<p>{{ $t('‘묵혼’ 온라인 대만 출시') }}</p>
								<p><span>06</span>{{ $t(' ‘고수’ 대만 출시') }}</p>
								<p><span>04</span>{{ $t(' ‘묵혼’ 온라인 한국 출시') }}</p>
								<p>{{ $t('‘고수‘ 모바일 한국 출시') }}</p> -->
							</div>
						</div>
					</div>
				</swiper-slide>
				<!-- <swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2019</div>
							<div class="content">
								<p><span>12</span>{{ $t(' 온라인 SF MMORPG ‘RF온라인’ 서비스 출시') }}</p>
								<p><span>11</span>{{ $t(' 씨씨알컨텐츠트리와 ‘포트리스 2M’ 퍼블리싱 계약 체결') }}</p>
								<p><span>04</span>{{ $t(' 모바일 MMORPG ‘무협왕’ 앱 스토어 출시') }}</p>
								<p><span>02</span>{{ $t(' 모바일 MMORPG ‘리그너스 대륙전기‘ 구글 플레이, 원 스토어 출시') }}</p>
								<p>{{ $t('모바일 SRPG ‘삼국 어웨이큰＇구글 플레이, 원 스토어 출시') }}</p>
								<p><span>01</span>{{ $t(' 모바일 MMORPG ‘십만대적검 : 리부트‘출시') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2018</div>
							<div class="content">
								<p><span>12</span>{{ $t(' 모바일 MMORPG ‘백가쟁패’ 구글 플레이, 원 스토어 출시') }}</p>
								<p>{{ $t('모바일 MMORPG ‘신의 아이들‘구글 플레이, 앱 스토어 ,원 스토어 출시') }}</p>
								<p><span>09</span>{{ $t(' 모바일 MMORPG ‘십만대적검’ 구글 플레이, 원 스토어 출시') }}</p>
								<p><span>08</span>{{ $t(' 모바일 MMORPG ‘여신전기‘ 구글 플레이, 원 스토어 출시') }}</p>
								<p><span>03</span>{{ $t(' 모바일 무협 MMORPG ‘군도‘ 구글 플레이, 원 스토어 출시') }}</p>
								<p><span>01</span>{{ $t(' 웹 MMORPG ‘촉산협객전‘ 서비스 출시') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2017</div>
							<div class="content">
								<p><span>11</span>{{ $t(' 블루사이드와 ‘킹덤언더파이어‘ 모바일 게임 개발 업무 협약 체결') }}</p>
								<p><span>08</span>{{ $t(' 모바일 게임 ‘형님 모바일’ 구글 플레이 무협모바일게임 장르 1위 달성') }}</p>
								<p><span>05</span>{{ $t(' 웹 무협 MMORPG ‘검우강호’ 사전 등록자 15만명 기록') }}</p>
								<p><span>04</span>{{ $t(' 웹게임 ‘무쌍 온라인‘ 서비스 출시') }}</p>
								<p>{{ $t('㈜팡게임, ‘㈜팡스카이’로 사명 변경') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2016</div>
							<div class="content">
								<p><span>12</span>{{ $t(' 모바일 해적 전략시물레이션 게임 ‘무적함대1942’ 구글 플레이 출시') }}</p>
								<p><span>10</span>{{ $t(' 모바일 MMOARPG ‘칼에 깃든 바람‘ 원 스토어 출시') }}</p>
								<p><span>05</span>{{ $t(' 모바일게임 ‘대황제M’ 구글 플레이,원 스토어 출시, 구글 플레이 전략게임 1위 달성') }}</p>
								<p><span>01</span>{{ $t(' 무협 웹게임 ‘운중가온라인‘ 서비스 오픈') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2015</div>
							<div class="content">
								<p><span>12</span>{{ $t(' PlamJoy와 웹게임 ‘운중가온라인’ 한국 퍼블리싱 계약 체결') }}</p>
								<p><span>08</span>{{ $t(' PC온라인게임 ‘대황제’ 네이버 등 8개사 채널링 계약') }}</p>
								<p>{{ $t('모바일게임 ‘바다속이야기’ 구글 플레이, N스토어 출시') }}</p>
								<p><span>07</span>{{ $t(' PC온라인게임 ‘데빌에이지‘ 서비스 오픈') }}</p>
								<p><span>02</span>{{ $t(' 벤처기업확인(벤처기업협회)') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2014</div>
							<div class="content">
								<p><span>11</span>{{ $t(' PC온라인게임 “뉴던전히어로” 오픈') }}</p>
								<p><span>08</span>{{ $t(' ‘기업부설연구소 설립(미래창조과학부)') }}</p>
								<p><span>07</span>{{ $t(' 중국 최대 검색포털 ‘바이두‘의 “바이두모바일게임＂과 협력체결') }}</p>
								<p>{{ $t('모바일 게임 “구몽:전생의기억“  구글 플레이 출시') }}</p>
								<p><span>05</span>{{ $t(' 모바일 게임 “케로로 액션히어로 for Kakao 구글 플레이, 앱 스토어 ,원 스토어 출시') }}</p>
								<p><span>01</span>{{ $t(' 국내 모바일게임사 서비스팀 인수') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2013</div>
							<div class="content">
								<p><span>12</span>{{ $t(' PC온라인게임 “구몽온라인“ 6개 채널링 동시오픈') }}</p>
								<p><span>10</span>{{ $t(' 중국 ‘광주명조네트워크’와 PC온라인게임 “구몽온라인“ 한국 독점 서비스 계약체결') }}</p>
								<p><span>08</span>{{ $t(' ‘㈜투윈게임즈’와 PC온라인게임 “원더킹“ 채널링 계약 체결') }}</p>
								<p><span>07</span>{{ $t(' ‘㈜이야소프트’와 PC온라인게임 “에다전설“ 국내 서비스 계약 체결') }}</p>
								<p><span>06</span>{{ $t(' 중국 ‘자모조이’와 모바일게임 “스와트앤좀비” 서비스 계약 체결') }}</p>
								<p>{{ $t('‘㈜미디어로그’와 “스와트앤좀비“ 해외 서비스 계약 체결') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="card-carousel--card">
						<div class="card-carousel--card--footer">
							<div class="title">2012</div>
							<div class="content">
								<p><span>12</span>{{ $t(' www.panggame.com 사이트 오픈') }}</p>
								<p><span>10</span>{{ $t(' 주식회사 팡 게임 설립') }}</p>
								<p>{{ $t('‘㈜더파이널파이브’와 PC온라인게임 ‘’와인드업‘ 서비스 계약 체결') }}</p>
							</div>
						</div>
					</div>
				</swiper-slide> -->
				<!-- Optional controls -->
				<div class="swiper-pagination"  slot="pagination"></div>   <!-- 分页 -->
			</swiper>
		</div>
  </div>
</template>
<script>
import request from "../../api/request"
export default {
  data() {
    return {
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: 980,
      items: [
        {name: 'Tycoon Thai', tag: "Thai"},
        {name: 'Ippudo', tag: "Japanese"},
        {name: 'Milano', tag: "Pizza"},
        {name: 'Tsing Tao', tag: "Chinese"},
        {name: 'Frances', tag: "French"},
        {name: 'Burma Superstar', tag: "Burmese"},
        {name: 'Salt and Straw', tag: "Ice cream"},
        {name: 'Salt and Straw', tag: "Ice cream"},
        {name: 'Salt and Straw', tag: "Ice cream"},
			],
			swiperOption: { 
				//是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
				notNextTick: true, 
				pagination: '.swiper-pagination', 
				slidesPerView: 'auto', 
				centeredSlides: true, 
				paginationClickable: true, 
				autoplay: 5000,
				spaceBetween: 30, 
				onSlideChangeEnd: swiper => { 
					//这个位置放swiper的回调方法 
					// this.page = swiper.realIndex+1; 
					// this.index = swiper.realIndex; 
				} 
			},
			fullWidth: 0, 
			sliderStyle:{
					width:''
			}, 
			historyList:[],
    }
  },
  computed: {
    atEndOfList() {
      return this.currentOffset <= (this.paginationFactor * -1) * 3;
    },
    atHeadOfList() {
      return this.currentOffset === 0;
		},
		swiper() { 
			return this.$refs.mySwiper.swiper; 
		}
	},
	mounted(){
		this.handleResize()
		this.getList()
  },
  methods: {
    moveCarousel(direction) {
      // Find a more elegant way to express the :style. consider using props to make it truly generic
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor;
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor;
      }
		},
		handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
      this.sliderStyle.width = this.fullWidth+'px'
		},
		getList(){
      let _this = this
      request.get('/news', {
        news_type__in:'7',need_content:true,limit:'999'
        }).then(function (res) {
        if (res.status == 200) {
					_this.historyList = res.data.results
        }
      })
    }
	},
	created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>


<style scoped>
.introduction-histort{
  margin:50px 0;
}
.card-carousel-wrapper {
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  align-items: center;
  justify-content: center;
  /* margin: 20px 0 40px; */
}
.card-carousel-cards{
  transform: translate3d(-1150px, 0px, 0px);
  transition: all 0s ease 0s;
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}
.card-carousel-cards .card-carousel--card{
  flex: 0 0 33.3333%;
}
.card-carousel {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.card-carousel--overflow-container {
  overflow: hidden;
  width: 980px;
}
.card-carousel--nav__left img, .card-carousel--nav__right img{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.card-carousel-cards {
  display: flex;
  transition: transform 150ms ease-out;
  transform: translatex(0px);
}
.card-carousel-cards .card-carousel--card {
  cursor: pointer;
  background-color: #fff;
  z-index: 3;
  margin-bottom: 2px;
}
.card-carousel-cards .card-carousel--card:first-child {
  margin-left: 0;
}
.card-carousel-cards .card-carousel--card:last-child {
  margin-right: 0;
}
.card-carousel-cards .card-carousel--card img {
  vertical-align: bottom;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: opacity 150ms linear;
  user-select: none;
}
.card-carousel-cards .card-carousel--card img:hover {
  opacity: 0.5;
}
.card-carousel-cards .card-carousel--card--footer {
  border-top: 0;
  margin: 0 10px;
}
.card-carousel-cards .card-carousel--card--footer .title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}
.card-carousel-cards .card-carousel--card--footer .content{
	width: 80%;
}
.card-carousel-cards .card-carousel--card--footer .content p{
  margin: 0;
  color: #6a6a6a;
  font-size: 12px;
}
.card-carousel-cards .card-carousel--card--footer .content p span{
  color: #1e1e1e;
  padding-right: 10px;
  font-weight: bold;
}
/* .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  position: relative;
  color: #666a73;
} */
.mb-history{
  display: block;
}
.pc-history{
  display: none;
  }
@media only screen and (min-width: 767px) {
  .mb-history{
    display: none;
  }
  .pc-history{
    display: block;
  }
}
.swiper-container{
  overflow: visible !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: -10px !important;
	left: 0;
	width: 100%;
}
.swiper-pagination-bullet-active{
  background: #000 !important;
}
.mb-history .histitle{
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
}
.mb-history .title{
	font-size: 18px;
	font-weight: bold;
}
.mb-history .content{
	color: #777777;
}
</style>
