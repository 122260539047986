<template>
  <div class="introduction-contact">
    <div class="contact-container container">
      <div class="info-box">
        <h3>CONTACT US</h3>
        <div class="contact_info">
          <span>{{ $t("전화 : ") }}</span
          ><a href="tel:070-4658-9400">070-4658-9400</a> <br /><span>{{
            $t("팩스 : ")
          }}</span
          ><a href="tel:070-8233-9383">070-8233-9383</a>
        </div>
        <div class="mailing-list">
          <div
            v-html="$t('서울시 강남구 논현로80길 25(역삼동,정우빌딩)')"
          ></div>
        </div>
        <div class="mb-map">
          <gmap-map
            :center="centers"
            :zoom="15"
            style="width: 200px; height: 200px"
            @click="updateMaker"
          >
            <gmap-marker :position="position" :draggable="false">
              <GmapInfoWindow
                ><div v-html="$t('서울특별시 강남구 논현로80길 25')"></div
              ></GmapInfoWindow>
            </gmap-marker>
          </gmap-map>
        </div>
      </div>
      <div class="map-box">
        <gmap-map
          :center="centers"
          :zoom="15"
          style="width: 300px; height: 300px"
          @click="updateMaker"
        >
          <gmap-marker :position="position" :draggable="false">
            <GmapInfoWindow>
              <div v-html="$t('서울특별시 강남구 논현로80길 25')"></div
            ></GmapInfoWindow>
          </gmap-marker>
        </gmap-map>
        <!-- <img src="../../assets/img/img_mapbg02_introduce.jpg" alt="" srcset=""> -->
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
export default {
  props: {
    position: {
      type: Object,
      default: () => {
        return {
          lat: 37.48574120693812,
          lng: 126.89423151344899,
        };
      },
    },
  },
  data() {
    return {
      place: null,
    };
  },
  computed: {
    google: gmapApi, // 获取官方的OBject 使用官方API的时候可以用
    centers() {
      return {
        lat: this.position.lat,
        lng: this.position.lng,
      };
    },
  },
  created() {},
  mounted() {},
  methods: {
    updateMaker: function(event) {
      console.log("updateMaker, ", event.latLng.lat(), event.latLng.lng());
      this.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.pointToAddress(
        this.position.lat,
        this.position.lng,
        this.pushAddress
      );
    },
    pushAddress(res) {
      this.$emit("mark", res, this.position);
    },
    pointToAddress(lat, lng, backcall) {
      // 实例化Geocoder服务用于解析地址
      var geocoder = new this.google.maps.Geocoder();
      // 地理反解析
      geocoder.geocode(
        { location: new this.google.maps.LatLng(lat, lng) },
        function geoResults(results, status) {
          if (status === this.google.maps.GeocoderStatus.OK) {
            backcall(results[0].formatted_address);
          } else {
            console.log("：error " + status);
          }
        }
      );
    },
  },
};
</script>

<style>
.gm-style-mtc {
  display: none;
}
.gm-control-active {
  display: none;
}
.gm-svpc {
  display: none;
}
/* .gmnoprint{
  display: none;
} */
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw-d {
  color: #000 !important;
}
.introduction-contact {
  background: url("../../assets/img/img_mapbg_introduce.jpg") no-repeat #3047a5;
  background-position: center top;
  color: #fff;
  position: relative;
}
.introduction-contact .contact-container {
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box; /* Firefox 17- */
  display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex; /* Firefox 18+ */
  display: -ms-flexbox; /* IE 10 */
  display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  justify-content: flex-start;
  align-items: center;
  height: 560px;
}
.introduction-contact .contact-container .info-box .contact_info {
  font-size: 14px;
  line-height: 1.4;
  margin: 44px 0 44px 0;
}
.introduction-contact .contact-container .info-box .contact_info a {
  color: #fff;
}
.introduction-contact .contact-container .info-box .mailing-list {
  color: rgba(255, 255, 255, 0.5);
}

.introduction-contact .contact-container .map-box {
  position: absolute;
  left: 50%;
  transform: translate3d(70%, 30%, 0);
  top: 0;
}
.introduction-contact .contact-container .map-box img {
  width: 100%;
}
.mb-map {
  display: none;
  margin-top: 20px;
}
.map-box {
  display: block;
}
@media only screen and (max-width: 1024px) {
  .mb-map {
    display: block;
  }
  .map-box {
    display: none;
  }
  .introduction-contact .contact-container .info-box .contact_info {
    margin: 10px 0;
  }
  .introduction-contact .mb-map img {
    max-width: 60%;
  }
}
</style>
