<template>
  <div class="introduction-about">
    <div class="title-box">
      <h3>{{ $t('안녕하세요. 팡스카이입니다.') }}</h3>
      <p>{{ $t('팡스카이는 게임 퍼블리싱 서비스를 기반으로') }}<br>{{ $t('한국을 포함한 전 세계에 특별한 재미와 경험을 제공하는 기업입니다.') }}</p>
      <p>{{ $t('창의적인 상상력을 바탕으로 온라인게임과 모바일게임, ') }}<br>{{ $t('그리고 콘텐츠 사업으로 영역을 확장하고 있으며,') }}<br>{{ $t('새로운 도전을 통해 글로벌 고객에게 즐거움을 선사할 것입니다.') }}</p>
    </div>
    <div class="content-box">
      <ul>
        <li
         v-for="(item,index) in contentList"
         :key="index"
        >
          <div class="content-img"><img :src="item.img" alt="" srcset=""></div>
          <div class="content-text">
            <p v-html="item[`title_${$i18n.locale}`]" class="t1"></p>
            <p v-html="item[`content_${$i18n.locale}`]" class="t2"></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {
    };
  },
  computed: {
      contentList () {
          return [
                {
                img: require("../../assets/img/ico_ico01_introduce.png"),
                title: this.$t('게임퍼블리싱을 위한') + "<br>" + this.$t('해외 네트워크'),
                content: this.$t('중국의 바이두를 비롯하여 약 40여 게임관련') + "<br>" + this.$t('기업들과 긴밀한 협력 네트워크를 구축 완료'),
                },
                {
                img: require("../../assets/img/ico_ico02_introduce.png"),
                title: this.$t('게임 확보 여력'),
                content:this.$t('해외에서 성공 케이스를 가진') + "<br>" + this.$t('중국 개발 게임들에 관한 판권 확보 가능'),
                },
                {
                img: require("../../assets/img/ico_ico03_introduce.png"),
                title: this.$t('게임퍼블리싱을 위한') + "<br>" + this.$t('국내 네트워크'),
                content: this.$t('팡스카이의 창업 멤버들은 한국 게임 업계 평균 19년') + "<br>" + this.$t('경력을 가지고 있으며, 다양한 인맥 및 리소스 네트워크를 보유'),
                },
                {
                img: require("../../assets/img/ico_ico04_introduce.png"),
                title: this.$t('안정적인 운영시스템'),
                content: this.$t('게임 퍼블리싱 업무 진행 경험을 바탕으로') + "<br>" + this.$t('뛰어난 리스크 관리 및 빠른 업무처리 및') + "<br>" + this.$t('Big Data 연구소를 보유하여 체계적 마케팅 가능'),
                },
            ]
      },
  },
  methods: {
     clickConfirmBtn(){ //点击了确认弹窗的确认按钮触发的事件
       console.log('请继续点击了confirm弹框确定按钮的逻辑')
       this.ModalRejectclick.isshow()//关闭弹窗
     },
     handleClick(type) {
          this.$refs.ModalRejectclick.show = true;
          this.clickType = type;
    },
    handleChange(val) {
      console.log(val);
    }
  },
};
</script>

<style>
.introduction-about{
  margin: 120px 0;
}
.introduction-about .title-box{
  text-align: center;
}
.introduction-about .title-box h3{
  color: #000;
  font-weight: bold;
}
.introduction-about .title-box p:nth-child(2){
  color: #000;
  font-weight: bold;
  margin: 36px 0 30px 0;
}
.introduction-about .title-box p:nth-child(3){
  color: #979797;
}
.introduction-about .content-box{
  margin: 100px 0;
}
.introduction-about .content-box ul{
  display: flex;	display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  align-items: center;    
  justify-content:center ;
}
.introduction-about .content-box ul li{
  flex-grow: 1;
  text-align: center;
  width: 30%;
  margin: 0 10px;
}
.introduction-about .content-box .content-img{
  width: 140px;
  height: 140px;
  margin:10px auto;
}
.introduction-about .content-box ul li img{
  width: 100%;
}
.introduction-about .content-text{
  height: 150px;
}
.introduction-about .content-box ul li .t1{
  height: 70px;
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.introduction-about .content-box ul li .t2{
  color: #979797;
  font-size: 14px;
}
@media only screen and (max-width: 767px){
  .introduction-about .content-box ul{
    flex-wrap: wrap;
  }
  .introduction-about .content-box ul li{
    flex-grow: 1;
    width: calc(calc(100% / 2) - 40px) !important;
  }
  .introduction-about .content-box ul li img{
    width: 80%;
  }
  .introduction-about{
    margin: 50px 0;
  }
  .introduction-about .content-box{
    margin: 30px 0 80px 0;
  }
}
</style>
